<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>Tractos</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="unidad.no_economico"
                label="No. Económico"
                class="uppercase"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="unidad.marca"
                label="Marca"
                class="uppercase"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="unidad.modelo"
                label="Modelo"
                class="uppercase"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="unidad.anio"
                label="Año"
                class="uppercase"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="unidad.placas"
                label="Placas"
                class="uppercase"
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="unidad.idtipo"
                :items="lista_tipos"
                item-text="descripcion"
                item-value="idtipo"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-file-input
                truncate-length="15"
                label="Tarjeta de Circulación"
                @change="onFileTCChange"
              ></v-file-input>
            </v-col>

            <v-col cols="4">
              <v-file-input
                truncate-length="15"
                label="Tarjeta Verificación Físico Mecánica"
                @change="onFileFMChange"
              ></v-file-input>
            </v-col>
            <v-col cols="4">
              <v-file-input
                truncate-length="15"
                label="Tarjeta Verificación Emisión"
                @change="onFileTVChange"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-file-input
                truncate-length="15"
                label="Póliza de Seguro"
                @change="onFilePolizaChange"
              ></v-file-input>
            </v-col>
            <v-col cols="4">
              <v-file-input
                truncate-length="15"
                label="Factura"
                @change="onFileFactChange"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-btn color="success" @click="saveunidad">
                <v-icon>mdi-content-save-outline</v-icon>
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import { url } from "../../main.js";
import moment from "moment-timezone";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Tractocamion"
  },
  data() {
    return {
      enviar: {},
      idtracto: this.$route.params.idtractocamion,
      edit: false,
      unidad: {
        idunidad: 0,

        no_economico: "",
        marca: "",
        modelo: "",
        anio: "",
        placas: "",
        idtipo: 0,

        tarjeta_circulacion: null,
        tarjeta_fm: null,
        tarjeta_emision: null,
        factura: null,
        seguro: null
      },
      lista_tipos: []
    };
  },
  mounted() {
    this.vertipos();
    this.permiso = localStorage.getItem("permission");
    if (this.idtracto == 0) {
    } else {
      this.editUnidad();
    }
  },
  methods: {
    editUnidad() {
      this.axios
        .get(url + "apiTractoCamiones.php/tractocamion/" + this.idtracto)
        .then(respuesta => {
          console.log(respuesta);
          this.unidad = respuesta.data;
          this.edit = true;
        });
    },
    vertipos() {
      this.axios
        .get(url + "apiTractoCamiones.php/get_tipos")
        .then(respuesta => {
          console.log(respuesta);
          this.lista_tipos = respuesta.data;

          this.unidad.idtipo = this.lista_tipos[0].idtipo;
        })
        .catch(function() {});
    },
    onFileFactChange(e) {
      this.unidad.factura = e.target.files[0];
      console.log(e.target.files);
    },
    onFileFMChange(e) {
      console.log(e);

      this.unidad.tarjeta_fm = e.target.files[0];
      //this.uploadFile(this.operador.documentacion.ine)
      console.log(e.target.files);
    },
    onFileTVChange(e) {
      console.log(e);

      this.unidad.tarjeta_emision = e.target.files[0];
      //this.uploadFile(this.operador.documentacion.ine)
      console.log(e.target.files);
    },
    onFilePolizaChange(e) {
      console.log(e);

      this.unidad.seguro = e.target.files[0];
      //this.uploadFile(this.operador.documentacion.ine)
      console.log(e.target.files);
    },
    onFileTCChange(e) {
      console.log(e);

      this.unidad.tarjeta_circulacion = e.target.files[0];
      //this.uploadFile(this.operador.documentacion.ine)
      console.log(e.target.files);
    },
    uploadFileFM(file, idtracto) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idtracto", idtracto);
      formData.append("tarjeta_fisico_mecanica", file);
      this.axios
        .post(
          url + "apiTractoCamiones.php/uploadFM",
          formData,

          {
            headers: {
              "Content-Type": file.type
            }
          }
        )
        .then(respuesta => {
          console.log(respuesta);
          // this.file= null;
          this.verunidades();
        })
        .catch(respuesta => {
          console.log(respuesta);
        });
    },
    uploadFileTC(file, idtracto) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idtracto", idtracto);
      formData.append("tarjeta_circulacion", file);
      this.axios
        .post(
          url + "apiTractoCamiones.php/uploadTC",
          formData,

          {
            headers: {
              "Content-Type": file.type
            }
          }
        )
        .then(respuesta => {
          console.log(respuesta);
          // this.file= null;
          this.verunidades();
        })
        .catch(respuesta => {
          console.log(respuesta);
        });
    },

    uploadFileEmision(file, idtracto) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idtracto", idtracto);
      formData.append("emision", file);
      this.axios
        .post(
          url + "apiTractoCamiones.php/uploadEmision",
          formData,

          {
            headers: {
              "Content-Type": file.type
            }
          }
        )
        .then(respuesta => {
          console.log(respuesta);
          // this.file= null;
          this.verunidades();
        })
        .catch(respuesta => {
          console.log(respuesta);
        });
    },
    uploadFileSeguro(file, idtracto) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idtracto", idtracto);
      formData.append("seguro", file);
      this.axios
        .post(
          url + "apiTractoCamiones.php/uploadSeguro",
          formData,

          {
            headers: {
              "Content-Type": file.type
            }
          }
        )
        .then(respuesta => {
          console.log(respuesta);
          // this.file= null;
          this.verunidades();
        })
        .catch(respuesta => {
          console.log(respuesta);
        });
    },
    uploadFileFactura(file, idtracto) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idtracto", idtracto);
      formData.append("factura", file);
      this.axios
        .post(
          url + "apiTractoCamiones.php/uploadFactura",
          formData,

          {
            headers: {
              "Content-Type": file.type
            }
          }
        )
        .then(respuesta => {
          console.log(respuesta);
          // this.file= null;
          this.verunidades();
        })
        .catch(respuesta => {
          console.log(respuesta);
        });
    },
    saveunidad() {
      console.log(this.unidad);
      if (this.validarcampos()) {
        console.log(this.unidad);
        this.axios
          .post(url + "apiTractoCamiones.php/tractocamion", this.unidad)
          .then(respuesta => {
console.log(respuesta);

         
            if (respuesta.data != 0) {
              this.edit = false;

              if (this.unidad.tarjeta_fm != null) {
                console.log("xxx");
                console.log(
                  this.uploadFileFM(this.unidad.tarjeta_fm, respuesta.data)
                );
              }

              if (this.unidad.tarjeta_circulacion != null) {
                console.log("xxx");
                console.log(
                  this.uploadFileTC(
                    this.unidad.tarjeta_circulacion,
                    respuesta.data
                  )
                );
              }
              if (this.unidad.tarjeta_emision != null) {
                this.uploadFileEmision(
                  this.unidad.tarjeta_emision,
                  respuesta.data
                );
              }

              if (this.unidad.seguro != null) {
                this.uploadFileSeguro(this.unidad.seguro, respuesta.data);
              }

              if (this.unidad.factura != null) {
                this.uploadFileFactura(this.unidad.factura, respuesta.data);
              }
                this.$swal("Información actualizada", "", "success");

              this.limpiarcampos();
            }
          
          })
          .catch(function() {
            console.log("FAILURE!!");
          });
      } else {
      }
    },
    limpiarcampos() {
      this.unidad.no_economico=0;
      this.unidad.marca="";
      this.unidad.anio="";
      this.unidad.modelo="";
      this.unidad.placas="";

      //Limpiar campo de tarjeta de circulación
      const tcinput = this.$refs.tcinput;
      tcinput.type = "text";
      tcinput.type = "file";

      //Limpiar campo de tarjeta fisicomecánica

      const fminput = this.$refs.fminput;
      fminput.type = "text";
      fminput.type = "file";

      //Limpiar campo de póliza de seguro
      const polizainput = this.$refs.polizainput;
      polizainput.type = "text";
      polizainput.type = "file";

      //Limpiar campo de factura

      const facturainput = this.$refs.facturainput;
      facturainput.type = "text";
      facturainput.type = "file";
    },
    validarcampos() {
      if (
        this.unidad.no_economico == "" ||
        this.unidad.modelo == "" ||
        this.unidad.placas == ""
        //||
        // this.unidad.telefono == ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    uploadFile(file, idoperador) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idoperador", idoperador);
      formData.append("ine", file);
      this.axios
        .post(
          url + "apiOperadores.php/uploadINE",
          formData,

          {
            headers: {
              "Content-Type": file.type
            }
          }
        )
        .then(respuesta => {
          console.log(respuesta);
          // this.file= null;
          this.veroperadores();
        })
        .catch(respuesta => {
          console.log(respuesta);
        });
    },
    uploadFilelic(file, idoperador) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idoperador", idoperador);
      formData.append("licencia", file);
      this.axios
        .post(
          url + "apiOperadores.php/uploadlicencia",
          formData,

          {
            headers: {
              "Content-Type": file.type
            }
          }
        )
        .then(respuesta => {
          console.log(respuesta);
          // this.file= null;
          this.veroperadores();
        })
        .catch(respuesta => {
          console.log(respuesta);
        });
    },
    uploadFileExamen(file, idoperador) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idoperador", idoperador);
      formData.append("examen_medico", file);
      this.axios
        .post(
          url + "apiOperadores.php/uploadexamen_medico",
          formData,

          {
            headers: {
              "Content-Type": file.type
            }
          }
        )
        .then(respuesta => {
          console.log(respuesta);
          // this.file= null;
          this.veroperadores();
        })
        .catch(respuesta => {
          console.log(respuesta);
        });
    }
  }
};
</script>
